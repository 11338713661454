/**
 * HorizonScroll
 *
 * @description Plugin for adding UI navigation control over overflow-x containers.
 *
 * @version 1.0.0
 * @author Tim Spears
 * @package diligent-institute-v2
 *
 */

import anime from 'animejs'


// Object Unique ID Function
;(function () {
    if (typeof Object.id == 'undefined') {
        var id = 0

        Object.id = function (o) {
            if (typeof o.__uniqueid == 'undefined') {
                Object.defineProperty(o, '__uniqueid', {
                    value: ++id,
                    enumerable: false,
                    writable: false,
                })
            }

            return o.__uniqueid
        }
    }
})()

// HorizonScroll ----------------------------------------------------------------------
$.fn.horizonScroll = function (options) {
    const $this = $(this)

    // Abort if no selector found.
    if ($this.length === 0) {
        return
    }

    // Set Default Options.
    const DEFAULT_SELECTORS = {
        trackClass: '.horizonScroll-track', // (string) class of overflow-x container to look for inside of parent section.
        slideClass: '.horizonScroll-slide', // (string) class used to identify slides/cards
        navClass: '.horizonScroll-nav', // (string) class to apply to navigation container
        prevArrow: '', // (string) class, HTML string or jQuery object
        nextArrow: '', // (string) class, HTML string or jQuery object
        navInsertType: '', // (string) Leave blank to keep nav at bottom of parent section. Options: 'prepend', 'append', 'before', 'after'
        navInsertSelector: '', // (string) DOM element to relocate NAV to.
        startingSlide: 1, // (number) Slide number to start as active and in view.
        scrollDuration: 400, // (number) in milliseconds
        scrollEasing: 'linear', // (string) Uses animeJS for transitions. List of easing options can be found at: https://animejs.com/documentation/#pennerFunctions
        placeholderSlide: false, // (bool) Add invisible slide at end of track to allow the true final slide to become fully left aligned on larger screens.
        placeholderSlideOffset: '', // (number) any offset to align the placeholder perfectly.
    }

    // Merge options with defaults
    options = $.extend({}, DEFAULT_SELECTORS, options)

    // A simple way to check for HTML strings
    // Strict HTML recognition (must start with <)
    // Extracted from jQuery v1.11 source
    const htmlExpr = /^(?:\s*(<[\w\W]+>)[^>]*)$/

    // Setup jQuery DOM elements ---------------------------------------------------------

    // Setup Parent Container
    $this.activeSlide = options.startingSlide // Store activeSlide in parent Object
    var instanceUID = Object.id($this) // Store unique instance ID in parent Object
    $this.attr({ id: 'horizonScroll-section-' + instanceUID }) // Apply unique selector for parent Object

    // Setup Overflow Track & Children
    $(
        $this
            .find(options.trackClass)
            .attr({ id: 'horizonScroll-track-' + instanceUID })
    )
    const $track = $('#horizonScroll-track-' + instanceUID)

    let trackWidth = $track.width()
    let scrollWidth = $track[0].scrollWidth
    let trackPosition = $track.scrollLeft()
    const $children = $track.find(options.slideClass)
    const $maxSlides = $children.length

    // Add unique slide ID to each child.
    $children.each(function (index, el) {
        $(el).attr('data-horizonScroll-index', index + 1)
    })

    // Overflow track needs to be position: relative for reading positions of slides.
    $track.css('position', 'relative')

    // Declare variables for global scope.
    let $activePos,
        $activeSlide,
        $activeWidth,
        $currentSlide,
        $navParent,
        $nextArrow,
        $prevArrow,
        activeNum,
        navContainer,
        scrollDuration,
        scrollEasing

    // Initalize ---------------------------------------------------------
    const initalize = () => {
        buildPlaceholder()
        updatePlaceholder()
        buildNav()
        bindEvents()
        toggleArrowActivation()
        disableNav()
        updateSlideClasses($this.activeSlide)
        findFirstSlide()
    }

    // Build Placeholder ---------------------------------------------------------

    const buildPlaceholder = () => {
        if (options.placeholderSlide) {
            const index = $children.length
            $children
                .parent()
                .append('<div class="horizonScroll-placeholder"></div>')
            $track
                .find('.horizonScroll-placeholder')
                .attr('data-horizonScroll-index', index + 1)
        }
    }

    // Build Nav ---------------------------------------------------------

    const buildNav = () => {
        navContainer =
            '<nav class="' + options.navClass.replace(/\./g, '') + '"></nav>' // Build Nav Container with custom class.
        $navParent = $($this.find(options.navInsertSelector)) // Find DOM object to attach Nav Container to.

        // Move Nav Container into DOM.
        switch (options.navInsertType) {
            case 'prepend':
                $(navContainer).prependTo($navParent)
                break
            case 'append':
                $(navContainer).appendTo($navParent)
                break
            case 'before':
                $(navContainer).insertBefore($navParent)
                break
            case 'after':
                $(navContainer).insertAfter($navParent)
                break
            default:
                $(navContainer).appendTo($this)
                break
        }

        // Build 'previous' trigger using custom or default html.
        if (htmlExpr.test(options.prevArrow)) {
            $prevArrow = $(options.prevArrow).addClass('horizonScroll-prev')
        } else {
            $prevArrow = $(
                '<button class="' +
                    options.prevArrow.replace(/\./g, '') +
                    ' horizonScroll-prev">Previous</button>'
            )
        }

        // Build 'next' trigger using custom or default html.
        if (htmlExpr.test(options.nextArrow)) {
            $nextArrow = $(options.nextArrow).addClass('horizonScroll-next')
        } else {
            $nextArrow = $(
                '<button class="' +
                    options.nextArrow.replace(/\./g, '') +
                    ' horizonScroll-next">Next</button>'
            )
        }

        // Add Triggers to Nav Container
        $prevArrow.prependTo($this.find(options.navClass))
        $nextArrow.appendTo($this.find(options.navClass))
    }

    // Bind Events ---------------------------------------------------------
    const bindEvents = () => {
        $(window).on('resize', () => {
            disableNav()
            updatePlaceholder()
        })
        $track.on('scroll', onScroll)
        $prevArrow.on('click', onPrevClick)
        $nextArrow.on('click', onNextClick)
    }

    // Find First Slide --------------------------
    const findFirstSlide = () => {
        $activeSlide = $($track.find('.active-slide'))
        $activePos = $activeSlide.position()
        $track.scrollLeft($activePos.left)
    }

    // Update Slide Classes
    const updateSlideClasses = (current) => {
        $currentSlide = $(
            $track.find(`[data-horizonScroll-index="${current}"]`)
        )
        $children.each(function (index, el) {
            $(el).removeClass('active-slide')
        })
        $currentSlide.addClass('active-slide')
    }

    // Move Track and update Slides on Trigger.
    const moveSlides = (direction) => {
        $activeSlide = $($track.find('.active-slide'))
        $activePos = $activeSlide.position()
        $activeWidth = $activeSlide.outerWidth(true)
        activeNum = $activeSlide.attr('data-horizonScroll-index')
        scrollDuration = options.scrollDuration
        scrollEasing = options.scrollEasing

        const maxSlideConditional = () => {
            if (options.placeholderSlide) {
                return activeNum <= $maxSlides
            } else {
                return activeNum < $maxSlides
            }
        }

        if (
            direction === 'prev' &&
            activeNum >= 1 &&
            $prevArrow.not('.-disabled')
        ) {
            anime({
                targets: '#horizonScroll-track-' + instanceUID,
                scrollLeft: '-=' + $activeWidth,
                duration: scrollDuration,
                easing: scrollEasing,
                complete: function (anime) {
                    trackPosition = $track.scrollLeft()
                    toggleArrowActivation()
                    if (activeNum > 1) {
                        $this.activeSlide = --activeNum
                        updateSlideClasses($this.activeSlide)
                    }
                },
            })
        }

        if (
            direction === 'next' &&
            maxSlideConditional() &&
            $nextArrow.not('.-disabled')
        ) {
            anime({
                targets: '#horizonScroll-track-' + instanceUID,
                scrollLeft: '+=' + $activeWidth,
                duration: scrollDuration,
                easing: scrollEasing,
                complete: function (anime) {
                    trackPosition = $track.scrollLeft()
                    toggleArrowActivation()
                    if (activeNum < $maxSlides) {
                        $this.activeSlide = ++activeNum
                        updateSlideClasses($this.activeSlide)
                    }
                },
            })
        }
    }

    // Toggle Arrow Activation ---------------------------------------------------------
    const toggleArrowActivation = () => {
        trackPosition === 0
            ? $prevArrow.addClass('-disabled')
            : $prevArrow.removeClass('-disabled')

        let maxScroll = scrollWidth - trackPosition - trackWidth

        maxScroll < 1
            ? $nextArrow.addClass('-disabled')
            : $nextArrow.removeClass('-disabled')
    }

    // Scroll Events ---------------------------------------------------------
    const onScroll = () => {
        trackPosition = $track.scrollLeft()
        $activeSlide = $($track.find('.active-slide'))
        activeNum = $activeSlide.attr('data-horizonScroll-index')
        $activeWidth = $activeSlide.outerWidth(true)
        $activePos = $activeSlide.position()

        // Update Slides when scrolling left (prev)
        if (trackPosition === 0) {
            updateSlideClasses(1)
        } else if ($activePos.left >= $activeWidth) {
            $this.activeSlide = --activeNum
            updateSlideClasses($this.activeSlide)
        }

        // Update Slides when scrolling right (next)
        if ($activePos.left <= $activeWidth * -1) {
            $this.activeSlide = ++activeNum
            updateSlideClasses($this.activeSlide)
        }

        toggleArrowActivation()
    }

    // Prev Arrow Functions ---------------------------------------------------------
    const onPrevClick = (e) => {
        e.preventDefault()
        moveSlides('prev')
    }

    // Next Arrow Functions ---------------------------------------------------------
    const onNextClick = (e) => {
        e.preventDefault()
        moveSlides('next')
    }

    // Window Resize Events ---------------------------------------------------------
    const disableNav = () => {
        trackWidth = $track.width()
        scrollWidth = $track[0].scrollWidth
        scrollWidth - trackWidth >= 1
            ? $this
                .find(options.navClass)
                .removeClass('horizonScroll-nav-disable')
            : $this.find(options.navClass).addClass('horizonScroll-nav-disable')
        toggleArrowActivation()
    }

    const updatePlaceholder = () => {
        const offsetPadding = options.placeholderSlideOffset
        const trackWidth = $track.width()
        const childWidth = $($children[0]).outerWidth()

        const $placeholder = $track.find('.horizonScroll-placeholder')
        const placeholderWidth = trackWidth - childWidth - offsetPadding

        $placeholder.css({
            'flex-basis': placeholderWidth,
            'flex-grow': 0,
            'flex-shrink': 0,
        })
    }

    // Ready ---------------------------------------------------------
    initalize()
}

export const horizonScroll = $.fn.horizonScroll()
