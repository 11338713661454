import 'babel-polyfill';
import Vue from 'vue';
import './accordion.plugin';
import './mobileNavigation.plugin';
import './dropdown.plugin';
import './stickyHeader.plugin';
import './stickyElement.plugin';
import 'sticksy';
import './jquery.visible';
import twitterFetcher from 'twitter-fetcher';
import Cookies from 'js-cookie';
import 'lazysizes';
import _ from 'lodash';
import 'slick-carousel/slick/slick';
import '@fancyapps/fancybox';
import 'animejs';

// Start New JS Modules from 2021 Work
import './accordians';
import './scrollers';
import './sliders';
import './sticky-asides';
import './modal';
// End New JS Modules from 2021 Work

window.$ = window.jQuery = jQuery;
window.Cookies = Cookies;

var stickySidebar = function () {
    var $sidebar = $('aside.primary');
    var offset = 82;
    var $window = $(window);
    var $sidebarForm = $('#sidebar-form');

    // Sidebar height
    $('main').css('min-height', $sidebar.height());

    // Sticky Sidebar
    if ($sidebarForm.length && $window.height() > ($sidebarForm.height() + $('header.primary').height()) && $window.width() >= 1024) {
        // Stop sticky sidebar on blog when reaching related
        // products. otherwise stop at site footer
        if ($sidebarForm.hasClass('is-blog')) {
            $sidebarForm.fix({
                startOffset: offset,
                untilVisible: $('.related-products-wrapper'),
            })
        }
        else if ($sidebarForm.hasClass('-stopAtSubFooter')) {
            $sidebarForm.fix({
                startOffset: offset,
                untilVisible: $('.subFooter'),
            })
        } else {
            $sidebarForm.fix({
                startOffset: offset,
                untilVisible: $('footer.primary'),
            });
        }
    }
}
window.stickySidebar = stickySidebar;

var gatedResourceRedirect = function () {
    let $popupFormHolder = $('.resource-gate');
    $popupFormHolder.on('click', 'input[type=submit]', function () {
        let resourceFile = $(this).closest('.resource-gate').data('resource-file');
        if (resourceFile) {
            setTimeout(
                function () {
                    if ($('.hs-error-msgs').length == 0) {
                        $.fancybox.close();
                        window.open(resourceFile, 'resource-document');
                    }
                },
                500
            );
        }
    });
}
window.gatedResourceRedirect = gatedResourceRedirect;

$(function () {

    // Mobile search
    var $mobileSearchIcon = $('.mobile-search-icon'),
        $mobileSearchField = $('.mobile-search-field');

    $mobileSearchIcon.on('click', function () {
        $mobileSearchField.fadeToggle();
        $mobileSearchField.focus();
    });

    // Sticky header functions
    var $header = $('header.primary');
    var $window = $(window);
    $header.stickyHeader();

    // Different header style on scroll
    $window.on('scroll resize', function () {
        $header.toggleClass('header-compact', $window.scrollTop() > 220);
    }).triggerHandler('scroll');


    // Accordion
    var $accordion = $('.locations-accordion');

    $accordion.accordion({
        hiddenOnLoad: true,
        singleOpen: true
    });

    // Main navigation
    var navIcon = $('#nav-icon');
    // mainNav     = document.getElementById('main-navigation'),
    // utilityNav  = document.getElementById('utility');

    navIcon.on('click', function () {
        navIcon.toggleClass('open');
        // mainNav.classList.toggle('active');
        // utilityNav.classList.toggle('active');
    });

    $('nav.primary').dropdown();

    $('nav.utility select.locale').on('change', (e) => {
        window.location = $(e.currentTarget).val();
    });

    let configProfile = {
        "profile": {
            "screenName": 'mclanahancorp'
        },
        "domId": 'twitterFetcher',
        "maxTweets": 3,
        "enableLinks": true,
        "showUser": true,
        "showTime": true,
        "showImages": true,
        "lang": 'en'
    };
    if ($('#twitterFetcher').length) {
        twitterFetcher.fetch(configProfile);
        setTimeout(function () {
            // Hack to hide broken video thumbnails returned from twitter
            $('#twitterFetcher .media').each(function () {
                if ($(this).find('img').attr('src').match(/ext_tw_video_thumb/)) {
                    $(this).hide();
                }
            })
        }, 1000)
    }

    let $supportDropdown = $('.support-dropdown');
    if ($supportDropdown.length) {
        let $select = $supportDropdown.find('select');
        $select.on('change', () => {
            if ($select.val()) {
                window.location = $select.val();
            }
        });
    }

    // Video thumbnail
    $('.video .video-thumbnail').on('click', function () {
        $(this).closest('.video').find('.embed-holder').show();
        $(this).remove();
    })

    // Object-fit polyfill
    var EdgeOrIE = detectEdgeOrIE();

    if (EdgeOrIE) {
        $('.related-item-image').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');
            if (imgUrl) {
                $container
                    .css('backgroundImage', 'url(' + imgUrl + ')')
                    .addClass('compat-object-fit');
            }
        });
        $('.resource-image-wrapper').each(function () {
            var $resourceContainer = $(this),
                resourceImgUrl = $resourceContainer.find('img').prop('src');
            if (imgUrl) {
                $resourceContainer
                    .css('backgroundImage', 'url(' + resourceImgUrl + ')')
                    .addClass('compat-object-fit');
            }
        });
        $('.gallery-item').each(function () {
            var $gallery = $(this),
                galleryImgUrl = $gallery.find('img').prop('src');
            if (galleryImgUrl) {
                $gallery
                    .css('backgroundImage', 'url(' + galleryImgUrl + ')')
                    .addClass('compat-object-fit');
            }
        });
        $('.slider-images').each(function () {
            var $productSliderImage = $(this),
                productSliderImageUrl = $productSliderImage.find('img').prop('src');
            if (productSliderImageUrl) {
                $productSliderImage
                    .css('backgroundImage', 'url(' + productSliderImageUrl + ')')
                    .addClass('compat-object-fit');
            }
        });
    }

    function detectEdgeOrIE() {
        var ua = window.navigator.userAgent;

        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    // Open external links in new window
    $('body').on('click', 'a', function (e) {
        var external = (
            !this.href.match(new RegExp(window.location.origin))
        );

        var empty = this.href == '';
        var js = this.href.match(/javascript/i);
        var file = this.href.match(/(\.pdf)/i);

        if (!empty && !js && (external || file)) {
            window.open(this.href);
            e.preventDefault();
        }
    });

    // Set persona after 30 seconds
    if ($('body[data-persona]').length) {
        let personaIds = $('body').data('persona');
        setTimeout(() => {
            $.get('/actions/mclanahan/mclanahan/set-persona', {
                personaIds: personaIds
            });
        }, 30000);
    }


    // Gated resource popup
    $('a.gated-popup').on('click', function (e) {
        e.preventDefault();

        let $gatedContent = $(this).siblings('.gated-content');
        let attachClass = $gatedContent.data('attach-class');
        let hubspotObject = _.unescape($(this).siblings('.escaped-form').html());
        let $gatedHtml = $($gatedContent.html());
        $gatedHtml.find('.resource-gate').append(`<div class="${attachClass}"></div>`);

        $.fancybox.open({
            src: $gatedHtml[0].outerHTML,
            type: 'html',
            baseClass: 'resource-popup',
            beforeShow: function () {
                // Manually run hubspot code to show form in popup
                eval(`hbspt.forms.create(${hubspotObject});`);
            }
        });
    });


    // Gated troubleshooting guide popup
    $('a.gated-guide').on('click', function (e) {
        e.preventDefault();

        let $gatedContent = $(this).closest('.support-guide--text').find('.gated-content');
        let attachClass = $gatedContent.data('attach-class');
        let hubspotObject = _.unescape($(this).closest('.support-guide--text').find('.escaped-form').html());
        let $gatedHtml = $($gatedContent.html());
        $gatedHtml.find('.resource-gate').append(`<div class="${attachClass}"></div>`);

        $.fancybox.open({
            src: $gatedHtml[0].outerHTML,
            type: 'html',
            baseClass: 'guide-popup',
            beforeShow: function () {
                // Manually run hubspot code to show form in popup
                eval(`hbspt.forms.create(${hubspotObject});`);
            }
        });
    });


    // Sticky sidebar
    if (!$('aside > .sidebar-form').length) {
        window.stickySidebar();
    }

    // Timeline History Sticky
    $('.timeline-navigation').fix({
        startOffset: 81,
    });


    // Add active states to top level nav
    let firstSegment = window.location.pathname.replace(new RegExp('^/([^/]*).*$'), '$1');
    $('nav.primary li[data-slug="' + firstSegment + '"]').addClass('active');


    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - 188
                }, 1000);
                return false;
            }
        }
    });


    // Redirect user to resource after they fill out gated form, uses click event to get around browser popup blocker
    window.gatedResourceRedirect();

    // Load More Pagination
    var loading = false;

    $('.wrapper').on('click', '[data-load-more-url]', function (e) {
        e.preventDefault();

        if (loading) {
            return;
        }

        loading = true;
        var $loadMore = $(this);

        // Show loading gif
        $loadMore

            .addClass('loading')
            .html($('<img>')
                .attr({
                    src: '/images/spinner.svg'
                }).css({
                    width: '45px'
                })
            );

        $(this).blur();

        $.get($(this).data('load-more-url'), function (html) {
            var $html = $(html);

            $html.find('[data-paginate] > *').appendTo("[data-paginate]");

            loading = false;

            if ($html.find('[data-load-more-url]').length) {
                $loadMore
                    .removeClass('loading')
                    .text('Load More')
                    .data('load-more-url', $html.find('[data-load-more-url]').data('load-more-url'));
                return;
            }

            $loadMore.remove();
        });
    });
});


// Make JumpLinks Sticky
(function stickyJumpLinks () {


    const hero = document.querySelector('.hero');
    const header = document.querySelector('header.primary');
    const jumpLinks = document.querySelector('.jumpLinks');

    if(!jumpLinks) return;


    window.addEventListener('scroll', e => {

        // If the header has reached the bottom of the page hero & the jumpLinks classList does not contain -scroll, add -scroll class
        if (hero.getBoundingClientRect().bottom <= header.getBoundingClientRect().height && !jumpLinks.classList.contains('-scroll') ){

            // Make JumpLink position top right under the header
            jumpLinks.style.top = `${header.getBoundingClientRect().height}px`

            //Add -scroll to JumpLink classList
            return jumpLinks.classList.add('-scroll')
        }

        // If the page hero is back within view, remove -scroll class
        if (hero.getBoundingClientRect().bottom > header.getBoundingClientRect().height){
            return jumpLinks.classList.remove('-scroll')
        }

    })


}())