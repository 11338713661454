import horizonScroll from './horizonScroll';

const prevArrowHTML = '<button class="cardSlider__arrow -prev"><span class="sr-only">Previous</span></button>'
const nextArrowHTML = '<button class="cardSlider__arrow -next"><span class="sr-only">Next</span></button>'


// js-cardScroller will pull the data set arrowMin
// ArrowMin is short for Arrow Minimum - Arrow Minimum sets the least number of slides
// needed for the arrows to show in the carousel
// If the number of Slides is greater than or equal to the arrowMinimum, then the arrow Nav will show

const navClass = ( numOfSlides, arrowMin = 4 ) => {

    return numOfSlides.length >= arrowMin ? '.cardSlider__nav': ''

}

// Initialize each resource card slider on the page
$('.js-cardScroller').each((index, element) => {

    const arrowMin = element.querySelector('[data-arrowMin]').dataset.arrowmin

    const selectAllSlides = element.querySelectorAll('.cardSlider__slide');

    $(element).horizonScroll({
        trackClass: '.cardSlider__slider',
        slideClass: '.cardSlider__slide',
        navClass: navClass(selectAllSlides, arrowMin),
        prevArrow: prevArrowHTML,
        nextArrow: nextArrowHTML,
        placeholderSlide: false
    })

})

const cardSlider = $('.js-cardSlider').horizonScroll({
    trackClass: '.cardSlider__slider',
    slideClass: '.cardSlider__slide',
    navClass: '.cardSlider__nav',
    prevArrow: prevArrowHTML,
    nextArrow: nextArrowHTML,
    placeholderSlide: false
})

/*const resourceCardSlider = $('.js-resourceCardSlider').horizonScroll({
    trackClass: '.resourceCardSlider__slider',
    slideClass: '.resourceCardSlider__slide',
    navClass: '.resourceCardSlider__nav',
    prevArrow: prevArrowHTML,
    nextArrow: nextArrowHTML,
    placeholderSlide: false
})*/