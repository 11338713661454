// Adds click event listener to all images with js-modal class
const imagesWithModal = document.querySelectorAll('.js-modalImage')
imagesWithModal.forEach((image) => {
    image.addEventListener('click', openModal)
})

const modalImageClass = "modal__image"

// Adds clicked image into modal and opens modal
function openModal(event){

    // Get the modal from the DOM
    const modal = document.querySelector('.js-modal')
   
    // Add an event listened to the close button
    modal.getElementsByTagName('BUTTON')[0].addEventListener('click', closeModal)

    // Show the modal (via showing the parent overlay)
    const modalOverlay = document.querySelector('.js-modalOverlay')
    modalOverlay.classList.add('-show')

}

// Closes modal
function closeModal(event){

    // Get the modal and remove the image
    const modal = document.querySelector('.js-modal')

    // Remove the event listener
    modal.querySelector('.modal__close').removeEventListener('click', closeModal)

    // Hide the modal via the overlay
    const modalOverlay = document.querySelector('.js-modalOverlay')
    modalOverlay.classList.remove('-show')

}

// Select all close buttons add run close Modal function
const closeButtons = document.querySelectorAll('.modal__close') 

closeButtons.forEach(button => {

    button.addEventListener('click', closeModal)

})


$('.js-modalSingle').each((index, element) => {
    $(element).slick({
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '0px',
        arrows: true,
        prevArrow: $('.modal__arrow.-prev'),
        nextArrow: $('.modal__arrow.-next'),
    });
});