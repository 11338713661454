/**
 *
 * jQuery Sticky Header Plugin
 */

;(function ($) {
    'use strict';

    var $window = $(window);
    $.fn.stickyHeader = function (options) {
        var plugin = this;
        var $body = $('body');

        return this.each(function () {

            // header hide and show on scroll up/down
            var didScroll;
            var lastScrollTop = 0;
            var delta         = 5;
            var initialHeight = $(this).removeClass('header-compact').outerHeight();
            var $header       = $(this);

            // set top padding on body so header doesn't overlap content
            $body.css('padding-top', initialHeight);

            $window.scroll(function(event){
                didScroll = true;
            });

            var checkHeight = function() {
                var currentHeight = $header.removeClass('header-compact').outerHeight();
                if (currentHeight != initialHeight) {
                    initialHeight = currentHeight;
                    $body.css('padding-top', initialHeight);
                }
                $window.triggerHandler('scroll');
            }

            setTimeout(checkHeight, 500);

            var scrollCheck = function() {
                if (didScroll) {
                    hasScrolled();
                    didScroll = false;
                }
                requestAnimationFrame(scrollCheck);
            };

            requestAnimationFrame(scrollCheck);

            var hasScrolled = function() {
                var scrollPos  = $window.scrollTop();

                var doNothing = (
                    scrollPos == lastScrollTop ||
                    Math.abs(lastScrollTop - scrollPos) <= delta
                );

                if (doNothing) {
                    return;
                }

                // // If they scrolled down and are past the header, add class .header-up.
                // if (scrollPos > lastScrollTop && scrollPos > initialHeight) {
                //     // Scroll Down
                //     $header
                //         .addClass('header-up')
                //         .css('top', -initialHeight);

                // } else {
                //     // Scroll Up
                //     $header
                //         .removeClass('header-up')
                //         .css('top', '0');

                // }

                // lastScrollTop = scrollPos;
            };

            $window.resize(checkHeight);

        });
    };

})(jQuery);
