// Allows for multiple accordians on the same page
const faqAccordians = document.getElementsByClassName('js-faq');
const openedClass = '-opened';

// Toggle function for faq question event listener
function toggleAnswer(event){

    // Toggles panel to open or close
    const parent = event.target.closest('.faq__question')
    const answer = parent.nextElementSibling
    answer.classList.toggle(openedClass)

    // Toggles arrow pointing up or down
    event.target.classList.toggle(openedClass)

    // Update ARIA for accessibility
    event.target.setAttribute('aria-expanded', (answer.classList.contains(openedClass) ? 'true' : 'false'))
}

// Loop through all accordians
for (const faq of faqAccordians) {

    // Find all the question buttons
    const faqButtons = faq.getElementsByClassName('js-faq-button')

    // Then, loop through all buttons and attach event listeners
    for (const faqButton of faqButtons) {
        faqButton.addEventListener('click', toggleAnswer)
    }
}